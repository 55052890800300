import React, {useRef, useState} from 'react';
import style from './assets/App.module.css';

function App() {
  const projects = [
    {
      title: 'Ассистент ТО (ASA)',
      url: 'techservice.devmk.ru',
      description: <div>Ассистент технического обслуживания авто - для контроля за своевременным выполнением ТО
        для <b>Android</b></div>,
      bg: '#E64A19',
      color: 'white',
      icon: 'tsm_logo_transparent-inline.svg'
    },
    {
      title: 'USW Stopwatch',
      url: 'apps.devmk.ru',
      description: <div>Секундомер / таймер в одном приложении для <b>Samsung Watch</b></div>,
      bg: '#519bfe',
      color: 'white',
      icon: 'usw.png'
    },
    {
      title: 'UST Cloud manager',
      url: 'ust.devmk.ru',
      description: <div>Облачный сервис для создания, публикации и синхронизации программ тренировок с приложением <b>UST
        Workout</b></div>,
      bg: '#f5862c',
      color: 'white',
      icon: 'ust.png'
    },
    {
      title: 'UMT Timer',
      url: 'umt.devmk.ru',
      description: <div>Мульти-таймер/секундомер для <b>Samsung Watch</b></div>,
      bg: '#8a99d2',
      color: 'white',
      icon: 'umt.png'
    },
    {
      title: 'Digital Interview',
      url: 'interview.devmk.ru',
      description: <div>Тестирование знаний языков программирования</div>,
      bg: 'rgb(0 93 148)',
      color: 'white',
      icon: 'interview_logo.png'
    },
  ];

  const refInfo = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [infoTextId, setInfoTextId] = useState({index: -1});
  const defaultTextBlock = (<div className={style.defaultBlock}>
    <div>DEV<b>MK</b></div>
    <div>
      <a href="https://telegram.im/@devmkru" target="new">
        <img src="telegram.png" width="30" alt="@devmkru"/>
        devmkru
      </a>
    </div>
  </div>);

  const renderCardProject = () => {
    return projects.map(({title, url, icon, bg, color}, index) => {
      const urlSite = `https://${url}`;

      return (
        <div
          key={url}
          className={style.card}
          style={{background: bg}}
          onClick={() => window.open(urlSite)}
          onMouseEnter={() => setInfoTextId({index})}
          ref={refInfo}
        >
          <div className={style.atomCard} style={{background: `white url(${urlSite}/${icon}) no-repeat center`}}/>
          <a style={{color}} className={style.cardTitle} href={urlSite} target='new'>{title}</a>
        </div>
      )
    })
  }

  return (
    <div className={style.container}>
      <div className={style.cards} onMouseLeave={() => setInfoTextId({index: -1})} ref={refContainer}>
        {renderCardProject()}

        <div
          key={"info"}
          className={style.infoBlock}
          style={{background: "black"}}
          onMouseEnter={() => setInfoTextId({index: -1})}
        >
          {defaultTextBlock}
        </div>
        <div className={style.info}>{infoTextId.index >=0 && <div>{projects[infoTextId.index].description}</div>}</div>
      </div>
    </div>
  );
}

export default App;
